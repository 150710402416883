import React, { FC, useContext } from "react";
import { TeaserProps, ColumnType } from "../generated-types";
import { sc, useSitecoreContext } from "~/foundation/Jss";
import { Ribbon } from "~/foundation/Components/Ribbon";
import { AspectRatio, Box, Flex, Heading, Text as ChakraText, useBreakpointValue } from "@chakra-ui/react";
import { ContentWrapper } from "~/foundation/Components/ContentWrapper";
import { imageSizes, themable } from "~/foundation/Theme";
import { orientation } from "../base";
import { ColorThemeContext } from "~/foundation/Theme/ColorThemeContext";
import { ImageSizeParameters } from "@sitecore-jss/sitecore-jss-react";

enum ImageRatio {
	Default,
	Uncropped
}

const Teaser: FC<TeaserProps> = (props) => {
	const { params, rendering } = props;
	const imageLast: boolean = params.orientation === orientation.TextImage;
	const smallerPadding = params?.spacing === "none" ? true : false;
	let imageRatio = ImageRatio.Default;

	const srcSets = {
		wideUncropped: [imageSizes.teaser.uncroppedLandscape.sm, imageSizes.teaser.uncroppedLandscape.md, imageSizes.teaser.uncroppedLandscape.lg],
		squareUncropped: [imageSizes.teaser.uncroppedSquare.sm, imageSizes.teaser.uncroppedSquare.md, imageSizes.teaser.uncroppedSquare.lg],
		wide: [imageSizes.teaser.landscape.sm, imageSizes.teaser.landscape.md, imageSizes.teaser.landscape.lg],
		square: [imageSizes.teaser.square.sm, imageSizes.teaser.square.md, imageSizes.teaser.square.lg]
	};

	const allSizes = {
		wide: "(min-width: 1760px) 880px, (min-width: 1440px) 592px, (min-width: 769px) 346px, 100vw",
		square: "(min-width: 1760px) 592px, (min-width: 769px) 482px, 100vw"
	};

	if (params.imageRatio === "1") {
		imageRatio = ImageRatio.Uncropped;
	}

	if (rendering.customData?.teaserType === ColumnType.OneColumn) {
		const srcSet = imageRatio === ImageRatio.Uncropped ? srcSets.wideUncropped : srcSets.wide;
		const sizes = allSizes.wide;

		return (
			<ContentWrapper py="0" pb={["16", null, "12"]}>
				<TeaserBaseWide itemId={rendering.dataSource} teaserProps={props} imageLast={imageLast} imageRatio={imageRatio} sizes={sizes} srcSet={srcSet} />
			</ContentWrapper>
		);
	}

	if (rendering.customData?.teaserType === ColumnType.TwoColumn) {
		const srcSet = imageRatio === ImageRatio.Uncropped ? srcSets.squareUncropped : srcSets.square;
		const sizes = allSizes.square;

		return (
			<TeaserBaseSquareMd itemId={rendering.dataSource} teaserProps={props} srcSet={srcSet} sizes={sizes} imageRatio={imageRatio} />
		);
	}

	const srcSet = imageRatio === ImageRatio.Uncropped ? srcSets.wideUncropped : srcSets.wide;
	const sizes = allSizes.wide;

	return (
		<Ribbon>
			<ContentWrapper
				py="0"
				pt={["var(--chakra-sizes-modulePY__SM)", null, "var(--chakra-sizes-modulePY__MD)", null, "var(--chakra-sizes-modulePY__XL)"]}
				pb={smallerPadding ? "0" : ["var(--chakra-sizes-modulePY__SM)", null, "var(--chakra-sizes-modulePY__MD)", null, "var(--chakra-sizes-modulePY__XL)"]}
			>
				<TeaserBaseWide itemId={rendering.dataSource} teaserProps={props} imageLast={imageLast} imageRatio={imageRatio} sizes={sizes} srcSet={srcSet} />
			</ContentWrapper>
		</Ribbon>
	);
};

const TeaserBaseWide: FC<{ imageLast: boolean, teaserProps: TeaserProps, itemId?: string, imageRatio: ImageRatio, sizes: string, srcSet: ImageSizeParameters[] }> = ({ imageLast, teaserProps: { fields }, itemId: itemId, imageRatio, sizes, srcSet }) => {
	const { sitecoreContext } = useSitecoreContext();
	const [theme] = useContext(ColorThemeContext);
	const isUncropped = imageRatio === ImageRatio.Uncropped;
	const lazyStyling = {
		".LazyLoad": {
			borderRadius: "lg",
			borderBottomRightRadius: "0"
		}
	}

	const imgComponent = () => (
		<sc.AdvanceImage
			field={fields?.teaserImage}
			itemId={itemId}
			fieldNames={["teaser image"]}
			sizes={sizes}
			srcSet={srcSet}
			sx={{ maxWidth: "100%", minWidth: "100%", borderRadius: "lg", borderBottomRightRadius: "0" }}
		/>
	);

	return (
		<Flex alignItems="center" flexDirection={["column", null, imageLast ? "row-reverse" : "row"]}>
			<Box flexBasis={["100%", null, "50%"]} w={["100%", null, "50%"]}>
				{(fields?.teaserImage?.value || sitecoreContext.pageEditing) &&
					<>
						{isUncropped ? (
							<Box borderRadius={"lg"} borderBottomRightRadius="0" overflow="hidden" sx={lazyStyling}>
								{imgComponent()}
							</Box>
						) : (

							<AspectRatio ratio={16 / 9} sx={lazyStyling}>
								{imgComponent()}
							</AspectRatio>
						)}
					</>
				}
				{(fields?.captionText?.value || sitecoreContext.pageEditing) &&
					<ChakraText variant="footerBody" maxW="482" mt={["4", null, "10"]} ms={["0", null, "12", null]} me={["4", null, "0", null]}>
						<sc.Text field={fields?.captionText} />
					</ChakraText>
				}
			</Box>
			<Box
				flex={["0 0 100%", null, "0 0 50%"]}
				w={["100%", null, "50%"]}
				ps={["0", null, "16", null, imageLast ? "6.25rem" : "6.875rem"]}
				pe={["0", null, "16", null, imageLast ? "6.875rem" : "6.25rem"]}>
				{(fields?.teaserHeadline?.value || sitecoreContext.pageEditing) &&
					<Heading as="h3" size="xs" mt={["8", null, "0"]} mb={["4"]}>
						<sc.Text field={fields?.teaserHeadline}/>
					</Heading>
				}
				{(fields?.teaserDescription?.value || sitecoreContext.pageEditing) &&
					<ChakraText
						as="div"
						mt={["4"]}
						size="body"
						sx={{
							'a': {
								textDecoration: "underline"
							}
						}}>
						<sc.RichText field={fields?.teaserDescription} />
					</ChakraText>
				}
				{(fields?.teaserLink?.value.href || sitecoreContext.pageEditing) &&
					<Box mt={["8"]}>
						<sc.Link field={fields?.teaserLink} variant="primary" size="md" color={theme.colors.ribbon.linkColor} />
					</Box>
				}
			</Box>
		</Flex>
	)
}

const TeaserBaseSquareMd: FC<{ teaserProps: TeaserProps, itemId?: string, imageRatio: ImageRatio, sizes: string, srcSet: ImageSizeParameters[] }> = ({ teaserProps: { fields }, itemId: itemId, imageRatio, sizes, srcSet }) => {
	const { sitecoreContext } = useSitecoreContext();
	const [theme] = useContext(ColorThemeContext);
	const isLargeViewport = useBreakpointValue({ base: false, lg: true }, { ssr: true });
	const isUncropped = imageRatio === ImageRatio.Uncropped;
	const lazyLoadStyling = {
		".LazyLoad": {
			maxWidth: "100%",
			minWidth: "100%",
			borderRadius: "lg",
			borderBottomRightRadius: "0"
		}
	}

	const imgComponent = () => (
		<sc.AdvanceImage
			field={fields?.teaserImage}
			itemId={itemId}
			fieldNames={["teaser image"]}
			sizes={sizes}
			srcSet={srcSet}
			sx={{ maxWidth: "100%", minWidth: "100%", borderRadius: "lg", borderBottomRightRadius: "0" }}
		/>
	)

	return (
		<Flex flexDirection="column" mx={["0", null, "12"]} flexGrow="1">
			{(fields?.teaserImage?.value || sitecoreContext.pageEditing) &&
				<Box mb="4">
					{isUncropped ? (
						<Box borderRadius={"lg"} borderBottomRightRadius="0" overflow="hidden" sx={lazyLoadStyling}>
							{imgComponent()}
						</Box>
					) : (
						<AspectRatio ratio={1 / 1} sx={lazyLoadStyling}>
							{imgComponent()}
						</AspectRatio>
					)}
					{(fields?.captionText?.value || sitecoreContext.pageEditing) &&
						<ChakraText variant="footerBody" maxW="482" mt={["4", null, "10"]} ms={["0", null, "12", null]} me={["4", null, "0", null]}>
							<sc.Text field={fields?.captionText} />
						</ChakraText>
					}
				</Box>
			}
			<Box
				mx="0">
				{(fields?.teaserHeadline?.value || sitecoreContext.pageEditing) &&
					<Heading as="h3" size="xs" my="4">
						<sc.Text field={fields?.teaserHeadline}/>
					</Heading>
				}
				{(fields?.teaserDescription?.value || sitecoreContext.pageEditing) &&
					<ChakraText
						as="div"
						mt={["4"]}
						size="body"
						sx={{
							'a': {
								textDecoration: "underline"
							}
						}}>
						<sc.RichText field={fields?.teaserDescription} />
					</ChakraText>
				}
				{(fields?.teaserLink?.value.href || sitecoreContext.pageEditing) &&
					<Box mt={["6"]}>
						<sc.Link field={fields?.teaserLink} variant={isLargeViewport ? "primary" : "secondary"} size="md" color={theme.colors.ribbon.linkColor} />
					</Box>
				}
			</Box>
		</Flex>
	)
}

export default themable()(Teaser, props => props.rendering.customData?.teaserType !== ColumnType.Default);